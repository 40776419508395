import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { paddingBottomByAspectRatio, positionAbsoluteAndStretch, rgbaWithHex, transition } from 'theme/utils'
import objectFitImages from 'object-fit-images'
import 'lazysizes'
import Image from 'next/image'

if (process.browser) {
	require('picturefill')
}

type LazyImageProps = {
	src: string
	srcRetina: string
	srcPlaceholder: string
	alt: string
	aspectRatio?: string
	loadEagerly?: boolean
}

const ImageWrapper = styled.div<{ $aspectRatio?: string; $loaded: boolean; delay?: number }>`
	position: relative;
	width: 100%;
	${props => props.$aspectRatio && paddingBottomByAspectRatio(props.$aspectRatio)}

	&::before {
		${props => props.$aspectRatio && positionAbsoluteAndStretch}
		content: ' ';
		${props => rgbaWithHex(props.theme.color.primary, 0.15, 'background')}
		opacity: ${props => (props.$loaded ? 0 : 1)};
		transition: ${props => transition('opacity', props.delay || 0)};
	}
`

const CustomNextImage = styled(Image).withConfig({
	shouldForwardProp: prop => !['visible', '$aspectRatio', 'delay'].includes(prop),
})<{ visible?: boolean; $aspectRatio?: string; delay?: number }>`
	${props => props.$aspectRatio && positionAbsoluteAndStretch}
	font-family: 'object-fit: cover; object-position: center;';
	object-fit: cover;
	object-position: center;
	opacity: ${props => (props.visible ? 1 : 0)};
	transition: ${props => transition('opacity', props.delay || 0)};
`

export const LazyImage = ({ alt, aspectRatio, srcRetina, loadEagerly = false }: LazyImageProps) => {
	const loadingRef = useRef<HTMLImageElement>(null)
	const actualRef = useRef<HTMLImageElement>(null)
	const [isPlaceholderLoaded, setIsPlaceholderLoaded] = useState(false)
	const [isActualLoaded, setIsActualLoaded] = useState(false)
	// const [pixelRatio, setPixelRatio] = useState<number>(1)

	// useEffect(() => {
	// 	if (typeof window !== 'undefined') {
	// 		setPixelRatio(window.devicePixelRatio)
	// 	}
	// }, [])

	useEffect(() => {
		if (loadingRef.current) {
			setIsPlaceholderLoaded(loadingRef.current.complete)
		}
		if (actualRef.current) {
			setIsActualLoaded(actualRef.current.complete)
		}
	}, [loadingRef, actualRef])

	useEffect(() => {
		if (isPlaceholderLoaded) {
			objectFitImages(loadingRef.current)
		}
		if (isActualLoaded) {
			objectFitImages(actualRef.current)
		}
	}, [isPlaceholderLoaded, isActualLoaded])

	return (
		<ImageWrapper ref={actualRef} $loaded={isActualLoaded} $aspectRatio={aspectRatio}>
			<CustomNextImage
				src={srcRetina}
				alt={alt}
				fill
				priority={loadEagerly}
				// Only apply lazy loading if not eagerly loaded
				loading={loadEagerly ? undefined : 'lazy'}
				onLoad={() => setIsActualLoaded(true)}
				visible={isActualLoaded ? true : undefined}
				$aspectRatio={aspectRatio}
			/>
		</ImageWrapper>
	)
}
