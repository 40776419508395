import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { boxShadowRgbaWithHex, transition } from 'theme/utils'
import { NextLink } from 'components/next-link/next-link'

type TagProps = {
	className?: string
	href: string
	children: PropTypes.ReactNodeLike
	transparent?: boolean
}

export const Tag = ({ className, href, children, transparent = false }: TagProps) => {
	return (
		<NextLink href={href} passHref>
			<TagLink className={className} $transparent={transparent}>
				<TagLinkText>{children}</TagLinkText>
			</TagLink>
		</NextLink>
	)
}

const tagLinkBoxShadow = (color: string, transparent: boolean) => {
	if (transparent) {
		return css`
			box-shadow: none;
		`
	}
	return css`
		${boxShadowRgbaWithHex('0 1px 2px 0', color, 0.1)}
	`
}

const TagLink = styled.span<{ $transparent: boolean }>`
	display: inline-flex;
	align-items: center;
	padding: ${props => (props.$transparent ? '3px 0' : '3px 8px')};
	color: ${props => (props.$transparent ? props.theme.color.category : props.theme.background)};
	text-decoration: none;
	text-transform: uppercase;
	letter-spacing: 0.1px;
	background-color: ${props => (props.$transparent ? 'transparent' : props.theme.color.category)};
	border-radius: 1px;
	${props => tagLinkBoxShadow(props.theme.colors.black, props.$transparent)}
	transition: ${transition('color')}, ${transition('background-color')};

	&:hover {
		color: ${props => (props.$transparent ? props.theme.color.secondary : props.theme.colors.white)};
		text-decoration: none;
		background-color: ${props => (props.$transparent ? 'transparent' : props.theme.color.secondary)};
	}
`

export const TagLinkText = styled.span`
	padding-top: 3px;
	font-size: 13px;
	font-weight: 700;
	line-height: 1;
`
